<template>
  <teleport to="body">
    <div v-if="show" class="conditions">
      <div class="title">
        Согласие на обработку персональных данных
      </div>
      <p>
        Покупатель (пользователь), совершая любые действия на сайте https://golden-pixel.kz/ (далее также – сайт) дает
        свое
        безусловное согласие ТОО «Золотой пиксель»,<br> БИН: 230840012089, (далее - Компания) и третьим лицам, которые
        имеют
        и
        (или) могут иметь отношение к деятельности Компании, в том числе, к заключению договора оферты между ним и
        Компанией, на сбор и обработку персональных данных, как на бумажных носителях, так и в электронном формате, в
        массивах и (или) базах данных Компании.
      </p>
      <p>
        1. Персональные данные — это любые относящиеся к покупателю (пользователю) сведения, зафиксированные на
        электронном,
        бумажном и (или) ином материальном носителе, которые могут содержать (включая, но не ограничиваясь):
      </p>
      <p>
        сведения, необходимые для регистрации на сайте Компании и надлежащей идентификации (фамилия, имя, отчество);
        электронный адрес, адрес доставки, контактный телефон);
        сведения, необходимые для поддержания связи (место доставки), номер телефона, адрес электронной почты;
        сведения, связанные с оказанием Компанией услуг в рамках заключенного между мною (или лицом, представителем
        которого
        я являюсь или могу являться) и Компанией - тексты договоров (соглашений), дополнительных соглашений к ним,
        заявления
        и согласия, переписка, документы и пр.;
        сведения о цели и характере правоотношений;
        иные сведения, необходимые Компании для заключения оферты или договора купли-продажи, и формирования документов
        в
        соответствии с требованиями законодательства Республики Казахстан и внутренними документами Компании;
        информация об изменении и(или) дополнении вышеуказанных данных.
      </p>
      <p>
        2. Сбор, обработка и использование персональных моих данных (включая сбор, обработку и хранение на бумажных
        носителях и (или) в электронном формате в массивах и (или) базах данных Компании), в том числе, для следующих
        целей:
      </p>
      <p>
        для заключения, совершения, исполнения оферты, договора купли -продажи;
        для выполнения Компанией обязанности по хранению и учету первичных документов, используемых в бухгалтерском
        учете
        в
        течение сроков, установленных законодательством Республики Казахстан;
        для судебной и внесудебной защиты прав Компании: (1) в случае нарушения обязательств по соответствующим
        договорам;
        (2) в случае возникновения спорных ситуаций, включая спорные ситуаций с третьими лицами;
        для размещения публичных (раскрытых) данных и сведений в средствах массовой информации с соблюдением требований
        законодательства Республики Казахстан;
        для предоставления отчетности и (или) информации в уполномоченные органы, аудиторским, оценочным и иным
        компетентным
        организациям;
        для обмена информацией, в том числе, для рассмотрения и (или) предоставления ответов на мои и (или) третьих лиц
        обращений, жалоб, предложений, претензий, поручений и т.д., передачи (получения) корреспонденции (почты) в мой
        адрес
        (адрес лица представителем, которого я являюсь или могу являться), для отправки (доставки)/получения посредством
        услуг курьера, курьерской службы, экспресс почты и т.д.;
        для маркетинговых целей, предоставления (пересылки) мне любых информационных и рекламных материалов, в том
        числе,
        о
        продуктах Компании, а также иных уведомлений Компании посредством телефонной, и иных видов связи, а также по
        открытым каналам связи (в том числе SMS, e-mail и т.п.);
        для иных целей, которые установлены (могут быть установлены) законодательством Республики Казахстан;
      </p>


      <p>
        3. Настоящим, Покупатель (пользователь) подтверждает, что:
      </p>

      <p>
        уведомлен о защите моих персональных данных и ознакомлен с Политикой обработки персональных данных;
        Компания вправе производить сбор и обработку персональных данных без согласия пользователя в случаях,
        установленных
        законами Республики Казахстан, в том числе, в иных организациях для целей проведения любых, не запрещенных
        законодательством Республики Казахстан, мероприятий;
        Компания вправе собирать (получать) персональные данные от любых третьих лиц, а также передавать персональные
        данные
        третьим лицам/распространять в общедоступных источниках персональных данных, с учетом требований действующего
        законодательства Республики Казахстан и требований международного права (требований международных договоров);
        настоящее согласие предоставляет Компании право, в установленном законодательством Республики Казахстан порядке,
        осуществлять с персональными данными любое действие (операцию) или совокупности таких действий (операций), в том
        числе, совершаемых с использованием средств автоматизации или без использования последних, включая, но не
        ограничиваясь, сбор, обработку, запись, копирование, резервное копирование, защиту, систематизацию, накопление,
        хранение внутри Компании и за его пределами (с соблюдением требований безопасности и конфиденциальности),
        уточнение
        (изменение, обновление), извлечение, использование, передачу (распространение, предоставление доступа, в том
        числе,
        передачу третьим лицам с правом обработки моих персональных данных, включая трансграничную передачу),
        обезличивание,
        блокирование, удаление, уничтожение;
        настоящее согласие может быть представлено третьим лицам в качестве доказательства о том, что согласие на сбор и
        обработку персональных данных было предоставлено мною ТОО «Золотой пиксель»
      </p>
      <div @click="close" class="close"></div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "PersonalConditions",
  props: ['show'],
  methods: {
    close() {
      this.$emit('update:show', false);
    }
  },
  updated() {
    document.body.style.overflow = this.show ? 'hidden' : null;
  }
}
</script>

<style scoped lang="scss">
@import "../assets/main";

.conditions {
  @extend .main-padding;
  @extend %font-regular;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color_dark;
  color: $font_color_light;
  box-sizing: border-box;
  z-index: 999;
  overflow: auto;
  padding-top: 100px;

  .title{
    @extend %font-middle;
    color: $font_color_light_golden;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    display: grid;
    align-items: center;
    justify-items: center;
    background: #ff00005c;
    user-select: none;
    transition: 0.1s linear;

    &:before, &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 4px;
      background: $font_color_golden;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>